<template>
  <div>
    <Header callBack @click="goBack"></Header>
    <div id="box">
      <!-- <div class="title">{{ anyNoticedetail.title }}</div>
      <div class="tit_item">{{ anyNoticedetail.created_at }}</div> -->
      <div class="true">
        <p v-html="anyNoticedetail.content"></p>
      </div>
    </div>
    <div
      v-if="isToHome && !$route.query.platform"
      class="openInApp"
      @click="openApp"
    >
      <div class="img">
        <img src="../../../static/image/logo1.png" alt="" />
      </div>
      <span>APP内打开</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      anyNoticedetail: {},
      isToHome: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === '/') {
        vm.isToHome = true;
      } else {
        vm.isToHome = false;
      }
    });
  },
  mounted() {
    this.id = this.$route.query.id;
    let params = {
      id: this.id,
    };
    this.$api.anyNoticedetail(params).then((res) => {
      if (res.code == 0) {
        this.anyNoticedetail = res.data;
      }
    });
  },
  methods: {
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
    },
    goBack() {
      if (this.isToHome) {
        this.$router.replace('/home');
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style>
.true img {
  max-width: 100%;
}
</style>
<style lang="less" scoped>
#box {
  padding: 20px;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }
  .tit_item {
    color: #999999;
    font-size: 12px;
    line-height: 28px;
  }
  .banner_top {
    width: 100%;
    // height: 175px;
    display: block;
    margin-bottom: 10px;
  }
  .darling {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  .true {
    // line-height: 22px;
    font-size: 12px;
    img {
      max-width: 100%;
    }
    p {
      margin-bottom: 10px;
    }
  }
  .plan {
    line-height: 20px;
    .plan_text {
      font-size: 14px;
      font-weight: 600;
    }
    .plan_num {
      font-size: 12px;
      color: #222222;
    }
  }

  .middle_box {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 8px;
    img {
      width: 222px;
      // height: 306px;
    }
    .middle_text {
      font-size: 12px;
    }
  }

  .join {
    .join_name {
      font-size: 14px;
      font-weight: 600;
      line-height: 30px;
    }
    .join_text {
      color: #222222;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .bot {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
  }

  .explain {
    margin-top: 10px;
    float: right;
    font-size: 14px;
    font-weight: 600;
  }
}
.openInApp {
  position: fixed;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  .img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  span {
    font-size: 12px;
  }
}
</style>
